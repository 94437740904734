"use client";
import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";

const CountryContext = createContext();

const CountryProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [loadedFromStorage, setLoadedFromStorage] = useState(false);

  const getData = async () => {
    try {
      const response = await axios.get("https://ipapi.co/json");

      sessionStorage.setItem("countryData", JSON.stringify(response?.data));
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching country data", error);
    }
  };

  useEffect(() => {
    const localData = sessionStorage.getItem("countryData");

    if (localData) {
      setData(JSON.parse(localData));
      setLoadedFromStorage(true);
    } else {
      getData();
    }


    const handleTabClose = () => {
      localStorage.removeItem("countryData");
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);
  return (
    <CountryContext.Provider value={{ data, loadedFromStorage }}>
      {children}
    </CountryContext.Provider>
  );
};

export const useCountry = () => {
  const context = useContext(CountryContext);

  if (!context) {
    throw new Error("useCountry must be used within a CountryProvider");
  }
  return context;
};

export default CountryProvider;
